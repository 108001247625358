<template>
  <section class="top-filter mb12">
    <a-form
      ref="formRef"
      class="form rms-form"
      layout="vertical"
      :model="formState"
    >
        <a-form-item label="字典类型" name="permType" class="rms-form-item">
          <a-select
            v-model:value="formState.type"
            placeholder="请选择"
            @change="typeChange"
          >
            <a-select-option value="ctf">证书</a-select-option>
            <a-select-option value="deptSkill">部门技能</a-select-option>
            <a-select-option value="generalSkill">通用技能</a-select-option>
            <a-select-option value="grade">职级</a-select-option>
            <a-select-option value="priority">优先级</a-select-option>
            <a-select-option value="industryLine">行业线</a-select-option>
            <a-select-option value="subServiceLine"
              >Service Line</a-select-option>
            <a-select-option value="role">Role</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="证书名称"
          name="ctfName"
          class="rms-form-item"
          v-show="formState.type === 'ctf'"
        >
          <a-input
            v-model:value="formState.ctfName"
            placeholder="请输入"
            allowClear
            autocomplete="off"
          />
        </a-form-item>

        <a-form-item
          label="部门"
          name="dept"
          class="rms-form-item"
          v-show="formState.type === 'deptSkill'"
        >
          <a-select
            v-model:value="formState.dept"
            placeholder="请选择"
            allowClear
          >
            <a-select-option
              v-for="item in categoryList"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="类别"
          name="category"
          class="rms-form-item"
          v-show="formState.type === 'generalSkill'"
        >
          <a-select
            v-model:value="formState.category"
            placeholder="请选择"
            allowClear
          >
            <a-select-option
              v-for="item in categoryList"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
    </a-form>
    <div class="rms-filter-btn-container">
      <div class="operation-items">
        <a-button type="primary" ghost @click="resetForm" class=""
                  v-show="formState.type === 'ctf' || formState.type === 'deptSkill' || formState.type === 'generalSkill'">
          <SyncOutlined />
        </a-button>
        <a-button type="primary" @click="doSearch" class="ml12" v-keyupSearch
                  v-show="formState.type === 'ctf' || formState.type === 'deptSkill' || formState.type === 'generalSkill'">
          <SearchOutlined />
        </a-button>
      </div>
    </div>
    <div class="buttons-wrap right">
      <a-space
          v-show="
            formState.type !== 'industryLine' &&
            formState.type !== 'subServiceLine'
          "
      >
        <Tooltip title="导出">
          <a-button
              class=""
              type="primary"
              ghost
              @click="exportClick"
              v-show="
              formState.type === 'deptSkill' ||
              formState.type === 'generalSkill' ||
              formState.type === 'ctf' ||
              formState.type === 'role'
            "
              v-auth="['system:dictionary:export']"
          >
            <ExportOutlined />
          </a-button>
        </Tooltip>
        <Tooltip title="新增">
          <a-button class="" type="primary" @click="addClick" v-auth="['system:dictionary:add']">
            <PlusOutlined />
          </a-button>
        </Tooltip>
      </a-space>
      <a-space
          v-show="
            formState.type === 'industryLine' ||
            formState.type === 'subServiceLine'
          "
      >
        <Tooltip title="导出">
          <a-button type="primary" ghost @click="exportClick" v-auth="['system:dictionary:export']">
            <ExportOutlined />
          </a-button>
        </Tooltip>
        <Tooltip title="下载模板">
          <a-button type="primary" ghost @click="downloadClick" v-auth="['system:dictionary:download']">
            <DownloadOutlined />
          </a-button>
        </Tooltip>
        <Tooltip title="导入">
          <a-button type="primary" ghost @click="uploadClick" v-auth="['system:dictionary:import']">
            <ImportOutlined />
          </a-button>
        </Tooltip>
      </a-space>
    </div>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onActivated, onMounted } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { SyncOutlined, SearchOutlined, ImportOutlined, DownloadOutlined, ExportOutlined, PlusOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'

export default defineComponent({
  name: "TopFilter",
  components: {Tooltip, SyncOutlined, SearchOutlined, ImportOutlined, DownloadOutlined, ExportOutlined, PlusOutlined },
  emits: [
    "search",
    "add-click",
    "export-click",
    "download-click",
    "upload-click",
    "resetTable",
  ],
  props: {},
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const formRef = ref();

    const resetForm = () => {
      formRef.value.resetFields();
      ctx.emit("search", formState);
    };

    const categoryList = ref([]);

    const getCategoryList = () => {
      const params = {
        dictBak1: formState.type === "deptSkill" ? "0" : "1",
      };
      $api.skillsComboBox(params).then((res) => {
        categoryList.value = res.data;
      });
    };

    const typeChange = () => {
      if (formState.type === "deptSkill" || formState.type === "generalSkill") {
        getCategoryList();
      }
      doSearch();
    };

    const formState = reactive({
      type: "ctf",
      ctfName: "",
      dept: undefined,
      category: undefined,
    });
    const doSearch = () => {
      ctx.emit("search", formState);
    };
    const addClick = () => {
      ctx.emit("add-click");
    };
    const exportClick = () => {
      ctx.emit("export-click");
    };
    const downloadClick = () => {
      ctx.emit("download-click");
    };
    const uploadClick = () => {
      ctx.emit("upload-click");
      // ctx.emit("resetTable");
    };

    onMounted(() => {
      doSearch();
    });

    onActivated(() => {
      ctx.emit("resetTable");
    });

    return {
      formState,
      formRef,
      categoryList,
      typeChange,
      doSearch,
      resetForm,
      addClick,
      exportClick,
      downloadClick,
      uploadClick,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  display: flex;
  width: 100%;
  position: relative;
  flex: 1;
  gap: 37px;
}
.rms-filter-btn-container {
  position: relative;
  width: 200px;
  .operation-items {
    position: absolute;
    bottom: 8px;
  }
}
.buttons-wrap{
  position: relative;
}


:deep(.ant-form-item) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  margin-bottom: 6px !important;
}

:deep(.ant-form-item-label) {
  width: 100%;
  text-align: left;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #2d3748;
  white-space: nowrap;
  padding: 0;

  label {
    width: 100%;
    text-align: left;
    word-wrap: break-word;
    word-break: break-word;
    display: inline-block;
    white-space: pre-wrap;
  }
}
:deep(.ant-form-item-control) {
  width: 100%;
}
:deep(.ant-input-affix-wrapper),
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector){
  border: 1px solid #cccccc !important;
}
:deep(.ant-select-arrow) {
  color: #cccccc;
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
