<template>
  <a-modal
    :getContainer="getRefsAff"
    width="40.6vw"
    :visible="visible"
    @cancel="closeModal"
    class="fill-assignment"
    centered
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "填写字典信息" }}
      </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
        <a-button @click="submitModal" type="primary" class="addEmpBtn">
          确定
        </a-button>
      </div>
    </template>
    <a-form
      layout="vertical"
      :model="formData"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
      class="org-form"
    >
      <div class="form-part">
        <a-form-item label="Code" name="dictCode">
          <a-input v-model:value="formData.dictCode" placeholder="请输入" />
        </a-form-item>
        <a-form-item
          :label="type === 'grade' ? 'Grade' : 'Priority'"
          name="dictValue"
        >
          <a-input v-model:value="formData.dictValue" placeholder="请输入" />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "FillDictionary",
  props: {
    title: String,
    type: String,
  },
  emits: ["refresh-table"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const visible = ref(false);
    const formRef = ref();
    const formData = ref({
      dictCode: "",
      dictValue: "",
    });
    const formRules = {
      dictCode: [
        {
          required: true,
          message: "Please input Code",
          trigger: "blur",
        },
      ],
      dictValue: [
        {
          required: true,
          message: "Please input Value",
          trigger: "blur",
        },
      ],
    };

    let actionType = "";
    const addClick = () => {
      actionType = "add";
      formData.value = {
        dictCode: "",
        dictValue: "",
      };
      visible.value = true;
    };

    const editClick = (row) => {
      actionType = "edit";
      formData.value.dictId = row.dictId;
      formData.value.dictCode = row.dictCode;
      formData.value.dictValue = row.dictValue;
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
      formRef.value.resetFields();
      formData.value = {
        dictCode: "",
        dictValue: "",
      };
    };

    const addFunc = (params) => {
      $api.addDictionary(params).then(() => {
        closeModal();
        ctx.emit("refresh-table");
        message.success("添加成功");
      });
    };
    const editFunc = (params) => {
      $api.updateDictionary(params).then(() => {
        closeModal();
        ctx.emit("refresh-table");
        message.success("编辑成功");
      });
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        let dictName = "";
        if (props.type === "grade") {
          dictName = "职级";
        } else {
          dictName = "优先级";
        }
        const params = {
          ...formData.value,
          dictName,
          dictType: props.type,
        };
        if (actionType === "add") {
          addFunc(params);
        } else {
          editFunc(params);
        }
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      closeModal,
      submitModal,
      visible,
      formData,
      formRef,
      formRules,
      getRefsAff,
      addClick,
      editClick,
    };
  },
});
</script>

<style lang="less" scoped>
.fill-assignment {
  width: 30vw;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  .footerBtn {
    text-align: right;
    margin: 0;
    position: relative;

    .cancelBtn,
    .addEmpBtn {
      margin-left: 15px;
    }

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }
}
</style>
