<template>
  <a-modal
    :getContainer="getRefsAff"
    width="40.6vw"
    :visible="visible"
    @cancel="closeModal"
    class="fill-assignment"
    centered
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "填写字典信息" }}
      </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
        <a-button @click="submitModal" type="primary" class="addEmpBtn">
          确定
        </a-button>
      </div>
    </template>
    <a-form
      layout="vertical"
      :model="formData"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
      class="org-form"
    >
      <div class="form-part">
        <a-form-item label="Certificate" name="certificate">
          <a-input v-model:value="formData.certificate" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="Group Name" name="groupName">
          <a-auto-complete
            v-model:value="formData.groupName"
            :options="groupNameOptions"
            placeholder="input here"
            :filter-option="filterOption"
            @change="groupNameChange"
            allowClear
          />
        </a-form-item>
        <a-form-item label="Group Id" name="groupId">
          <a-auto-complete
            v-model:value="formData.groupId"
            :options="groupIdOptions"
            placeholder="input here"
            :filter-option="filterOption"
            @change="groupIdChange"
            allowClear
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "FillCtf",
  props: {
    title: String,
  },
  emits: ["refresh-table"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const visible = ref(false);
    const formRef = ref();
    const groupNameOptions = ref([]);
    const groupIdOptions = ref([]);
    const formData = ref({
      certificate: "",
      groupId: "",
      groupName: "",
    });
    const formRules = {
      certificate: [
        {
          required: true,
          message: "Please input Certificate",
          trigger: "change",
        },
      ],
      groupId: [
        {
          required: true,
          message: "Please select GroupId",
          trigger: "change",
        },
      ],
      groupName: [
        {
          required: true,
          message: "Please select GroupName",
          trigger: "change",
        },
      ],
    };

    const groupList = ref([]);
    const getCtfComboBox = () => {
      $api.ctfComboBox().then((res) => {
        groupList.value = res.data;
        groupNameOptions.value = groupList.value.map((item) => {
          return {
            text: item.groupName,
            value: item.groupName,
          };
        });
        groupIdOptions.value = groupList.value.map((item) => {
          return {
            text: item.groupId,
            value: item.groupId,
          };
        });
      });
    };

    let actionType = "";
    const addClick = () => {
      actionType = "add";
      formData.value.certificate = "";
      formData.value.groupId = "";
      formData.value.groupName = "";
      visible.value = true;
    };

    const editClick = (row) => {
      actionType = "edit";
      formData.value.ctfId = row.ctfId;
      formData.value.certificate = row.certificate;
      formData.value.groupId = row.groupId;
      formData.value.groupName = row.groupName;
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
      formRef.value.resetFields();
      formData.value.certificate = "";
      formData.value.groupId = "";
      formData.value.groupName = "";
    };

    const addFunc = (params) => {
      $api.addCtf(params).then(() => {
        closeModal();
        ctx.emit("refresh-table");
        message.success("添加成功");
      });
    };
    const editFunc = (params) => {
      $api.updateCtf(params).then(() => {
        closeModal();
        ctx.emit("refresh-table");
        message.success("编辑成功");
      });
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        // const group = groupList.value.find(
        //   (item) => item.groupId === formData.value.groupId
        // );
        const params = {
          ...formData.value,
          // groupName: group.groupName
        };
        if (actionType === "add") {
          addFunc(params);
        } else {
          editFunc(params);
        }
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    onMounted(() => {
      getCtfComboBox();
    });

    const filterOption = (input, option) => {
      if (!input) return false;
      return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };

    let inputNewGroupName = "";
    let inputNewGroupId = "";
    const groupNameChange = (value) => {
      inputNewGroupName = value;
      if (!value) {
        formData.value.groupId = "";
        inputNewGroupId = "";
        formRef.value.validateFields();
        return;
      }
      let obj = groupList.value.find((item) => {
        return item.groupName === value;
      });
      if (obj) {
        formData.value.groupId = obj.groupId;
        formRef.value.validateFields();
      } else {
        formData.value.groupId = inputNewGroupId ? inputNewGroupId : "";
      }
    };

    const groupIdChange = (value) => {
      inputNewGroupId = value;
      if (!value) {
        formData.value.groupName = "";
        inputNewGroupName = "";
        formRef.value.validateFields();
        return;
      }
      let obj = groupList.value.find((item) => {
        return item.groupId === value;
      });
      if (obj) {
        formData.value.groupName = obj.groupName;
        formRef.value.validateFields();
      } else {
        formData.value.groupName = inputNewGroupName ? inputNewGroupName : "";
      }
    };

    return {
      groupNameChange,
      groupIdChange,
      filterOption,
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      closeModal,
      submitModal,
      visible,
      formData,
      formRef,
      formRules,
      groupList,
      getRefsAff,
      addClick,
      editClick,
      groupIdOptions,
      groupNameOptions,
    };
  },
});
</script>

<style lang="less" scoped>
.fill-assignment {
  width: 30vw;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  .footerBtn {
    text-align: right;
    margin: 0;
    position: relative;

    .cancelBtn,
    .addEmpBtn {
      margin-left: 15px;
    }

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }
}
</style>
