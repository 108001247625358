<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @download-click="downloadClick"
      @upload-click="uploadClick"
      @export-click="exportClick"
      @add-click="addClick"
      @resetTable="handleResetTable"
    />
    <import-modal
      v-model:visible="modalVisible"
      :upload-params="{ dictType: searchCondition.type }"
      :apiFuncName="apiFuncName"
      @closeModal="handleCloseModal"
      @resetTable="handleResetTable"
    />
    <DictionaryList
      ref="dictionaryListRef"
      @edit-click="editClick"
      @del-click="delClick"
    >
    </DictionaryList>
    <FillDictionary
      ref="fillDictionaryRef"
      :type="searchCondition.type"
      @refresh-table="handleSearch"
    >
    </FillDictionary>
    <FillSkill
      ref="fillSkillRef"
      :type="searchCondition.type"
      @refresh-table="handleSearch"
    >
    </FillSkill>
    <FillRole
        ref="fillRoleRef"
        :type="searchCondition.type"
        @refresh-table="handleSearch"
    >
    </FillRole>
    <FillCtf ref="fillCtfRef" @refresh-table="handleSearch"></FillCtf>
  </section>

  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import DictionaryList from "./components/DictionaryList";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";
import FillDictionary from "@/views/home/ConfigManagement/Dictionary/components/FillDictionary";
import FillSkill from "@/views/home/ConfigManagement/Dictionary/components/FillSkill";
import FillCtf from "@/views/home/ConfigManagement/Dictionary/components/FillCtf";
import FillRole from '@/views/home/ConfigManagement/Dictionary/components/FillRole'
import { message } from "ant-design-vue";
import ImportModal from "@/components/ImportModal";
import ModalBox from '@/components/ModalBox'

export default defineComponent({
  name: "maintainDictionary",
  components: {
    ImportModal,
    FillCtf,
    FillSkill,
    FillDictionary,
    DictionaryList,
    Filter,
    FillRole,
    ModalBox
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const apiFuncName = ref("importDictionaries")
    const dictionaryListRef = ref();
    let searchCondition = ref({});
    const handleSearch = (searchParams) => {
      if (searchParams) {
        searchCondition.value = searchParams;
      }
      dictionaryListRef.value.searchClick(searchCondition.value);
    };
    const modalVisible = ref(false);
    const uploadClick = () => {
      apiFuncName.value = searchCondition.value.type === 'industryLine' ? 'importIndustryLine' : 'importDictionaries'
      modalVisible.value = true;
    };
    const handleCloseModal = () => {
      modalVisible.value = false;
    };
    const handleResetTable = () => {
      dictionaryListRef.value.doSearch();
    };

    const downloadClick = () => {
      const params = {
        dictType: searchCondition.value.type,
      };
      let name = "";
      switch (searchCondition.value.type) {
        case "industryLine":
          name = "行业线";
          $api.exportIndustryLineTemplate().then((res) => {
            downloadFile(res.file, `${name}模板.xls`);
          });
          break;
        case "subServiceLine":
          name = "Service Line ";
          $api.exportDicTemplate(params).then((res) => {
            downloadFile(res.file, `${name}模板.xls`);
          });
          break;
      }
    };
    const exportClick = () => {
      let exportUrl = '/staffDictionary/exportDictionaries'
      if (searchCondition.value.type === "ctf") {
        $api.exportStaffCtf().then((res) => {
          downloadFile(res.file, "证书数据.xls");
        });
      } else {
        let name = "";
        let dictBak1;
        let dictType = searchCondition.value.type;
        switch (searchCondition.value.type) {
          case "deptSkill":
            name = "部门技能";
            dictBak1 = "0";
            dictType = "skillName";
            break;
          case "generalSkill":
            name = "通用技能";
            dictBak1 = "1";
            dictType = "skillName";
            break;
          case "industryLine":
            name = "行业线";
            exportUrl = '/staffPlInfo/exportPlInfoData'
            break;
          case "subServiceLine":
            name = "Service Line ";
            break;
          case "role":
            name = "Role";
            exportUrl = '/staffDictionary/exportRoles'
            break;
        }
        const params = {
          dictType,
          dictBak1,
        };
        $api.exportDictionaries(params, exportUrl).then((res) => {
          console.log(res);
          downloadFile(res.file, `${name === '行业线' ? res.filename : name + '数据.xls'}`);
        });
      }
    };

    const fillTitle = ref("");
    const fillDictionaryRef = ref();
    const fillSkillRef = ref();
    const fillCtfRef = ref();
    const fillRoleRef = ref();
    const addClick = () => {
      fillTitle.value = "新增字典信息";
      switch (searchCondition.value.type) {
        case "ctf":
          fillCtfRef.value.addClick();
          break;
        case "grade":
        case "priority":
          fillDictionaryRef.value.addClick();
          break;
        case "deptSkill":
        case "generalSkill":
          fillSkillRef.value.addClick();
          break;
        case "role":
          fillRoleRef.value.addClick();
          break;
      }
    };
    const editClick = (row) => {
      fillTitle.value = "编辑字典信息";
      switch (searchCondition.value.type) {
        case "ctf":
          fillCtfRef.value.editClick(row);
          break;
        case "grade":
        case "priority":
          fillDictionaryRef.value.editClick(row);
          break;
        case "deptSkill":
        case "generalSkill":
          fillSkillRef.value.editClick(row);
          break;
        case "role":
          fillRoleRef.value.editClick(row);
          break;
      }
    };

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {item, delFunc} = modalData.value
      if(delFunc) {
        $api[delFunc](item).then((res) => {
          if (res.code == 200) {
            message.success(res.msg);
          }
          dictionaryListRef.value.doSearch();
        });
      }else {
        throw new Error('请传入删除方法。');
      }
    }
    const handleDel = (item, delFunc) => {
      content.value = '确定删除这条数据吗?'
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = {item, delFunc}
      modelValue.value = true
      /*Modal.confirm({
        title: "确定删除这条数据吗?",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          if(delFunc) {
            $api[delFunc](item).then((res) => {
              if (res.code == 200) {
                message.success(res.msg);
              }
              dictionaryListRef.value.doSearch();
            });
          }else {
            throw new Error('请传入删除方法。');
          }
        },
      });*/
    }

    const delClick = (row) => {
      switch (searchCondition.value.type) {
        case "ctf":
          handleDel(row, 'delCtf');
          break;
        case "role":
          handleDel(row, 'delRoleDic');
          break;
        case "deptSkill":
        case "generalSkill":
        case "grade":
        case "priority":
          handleDel(row, 'delOtherCtf');
          break;
        default:
          break;
      }
    };

    return {
      delClick,
      dictionaryListRef,
      handleSearch,
      exportClick,
      downloadClick,
      modalVisible,
      uploadClick,
      handleCloseModal,
      handleResetTable,
      fillCtfRef,
      fillDictionaryRef,
      fillSkillRef,
      fillRoleRef,
      fillTitle,
      addClick,
      editClick,
      searchCondition,
      apiFuncName,
      modelValue,
      content,
      showIconCom,
      handleOk,
    };
  },
});
</script>
