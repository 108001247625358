<template>
  <section class="org-list">
    <a-table
      ref="dictionaryListRef"
      :columns="columns"
      :data-source="dictionaryList"
      rowKey="dictId"
      :scroll="{
        y: tableHeight,
        x: 1000,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
    >
      <template #operation="{ record }">
<!--        <a-space>
          <span class="operationLink underline" @click="editClick(record)" v-auth="['system:dictionary:edit']">
            编辑
          </span>
          <span class="redLink underline" @click="delClick(record)" v-auth="['system:dictionary:delete']"> 删除 </span>
        </a-space>-->
        <ActionBtn v-bind="record" :btnList="btnList" @FormOutlined="editClick(record)" @DeleteOutlined="delClick(record)" />
      </template>
    </a-table>
  </section>
</template>
<script>
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '编辑',
    icon: 'FormOutlined',
    auth: ['system:dictionary:edit']
  },
  {
    name: '删除',
    icon: 'DeleteOutlined',
    auth: ['system:dictionary:delete']
  },
]
export default defineComponent({
  name: "OrganizationList",
  components: {ActionBtn},
  emits: ["edit-click", "del-click"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const dictionaryListRef = ref();
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch();
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch();
      },
    });
    const dictionaryList = ref();
    let searchCondition = ref({});

    const searchDictionary = () => {
      const params = {
        type: searchCondition.value.type,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getDictionaryMaintainList(params).then((res) => {
        pagination.total = res.data.total;
        dictionaryList.value = res.data.resource;
      });
    };

    const searchIndustryLine = () => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getIndustryLineList(params).then((res) => {
        pagination.total = res.data.total;
        dictionaryList.value = res.data.resource;
      });
    };

    const searchCtf = () => {
      const params = {
        certificate: searchCondition.value.ctfName,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getCtfMaintainList(params).then((res) => {
        pagination.total = res.data.total;
        dictionaryList.value = res.data.resource;
      });
    };

    const searchSkill = () => {
      const dictBak1 = searchCondition.value.type === "deptSkill" ? "0" : "1";
      const dictRemark =
        searchCondition.value.type === "deptSkill"
          ? searchCondition.value.dept
          : searchCondition.value.category;
      const params = {
        dictBak1,
        dictRemark,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getSkillMaintainList(params).then((res) => {
        pagination.total = res.data.total;
        dictionaryList.value = res.data.resource;
      });
    };

    const searchRoles = () => {
      const params = {
        type: searchCondition.value.type,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getRolesList(params).then((res) => {
        pagination.total = res.data.total;
        dictionaryList.value = res.data.resource;
      });
    };

    const doSearch = () => {
      switch (searchCondition.value.type) {
        case "ctf":
          searchCtf();
          break;
        case "industryLine":
          searchIndustryLine();
          break;
        case "grade":
        case "priority":
        case "subServiceLine":
          searchDictionary();
          break;
        case "deptSkill":
        case "generalSkill":
          searchSkill();
          break;
        case "role":
          searchRoles();
          break;
      }
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition.value = searchParams;
      doSearch();
    };

    const editClick = (record) => {
      ctx.emit("edit-click", record);
    };

    const columns = computed(() => {
      switch (searchCondition.value.type) {
        case "ctf":
          return [
            {
              title: "Certificate",
              dataIndex: "certificate",
              key: "certificate",
              width: 600,
              ellipsis: true,
            },
            {
              title: "Group Name",
              dataIndex: "groupName",
              key: "groupName",
              width: 200,
              ellipsis: true,
            },
            {
              title: "Group ID",
              dataIndex: "groupId",
              key: "groupId",
              ellipsis: true,
            },
            {
              title: "操作",
              dataIndex: "operation",
              slots: { customRender: "operation" },
              width: 100,
              fixed: "right",
            },
          ];
        case "deptSkill":
          return [
            {
              title: "Code",
              dataIndex: "dictCode",
              key: "dictCode",
              width: 200,
              ellipsis: true,
            },
            {
              title: "Skill",
              dataIndex: "dictValue",
              key: "dictValue",
              width: 300,
              ellipsis: true,
            },
            {
              title: "Dept",
              dataIndex: "dictRemark",
              key: "dictRemark",
              ellipsis: true,
            },
            {
              title: "操作",
              dataIndex: "operation",
              slots: { customRender: "operation" },
              width: 80,
              fixed: "right",
            },
          ];
        case "generalSkill":
          return [
            {
              title: "Code",
              dataIndex: "dictCode",
              key: "dictCode",
              width: 200,
              ellipsis: true,
            },
            {
              title: "Skill",
              dataIndex: "dictValue",
              key: "dictValue",
              width: 300,
              ellipsis: true,
            },
            {
              title: "Category",
              dataIndex: "dictRemark",
              key: "dictRemark",
              ellipsis: true,
            },
            {
              title: "操作",
              dataIndex: "operation",
              slots: { customRender: "operation" },
              width: 80,
              fixed: "right",
            },
          ];
        case "grade":
          return [
            {
              title: "Code",
              dataIndex: "dictCode",
              key: "dictCode",
              width: 120,
              ellipsis: true,
            },
            {
              title: "Grade",
              dataIndex: "dictValue",
              key: "dictValue",
              ellipsis: true,
            },
            {
              title: "操作",
              dataIndex: "operation",
              slots: { customRender: "operation" },
              width: 80,
              fixed: "right",
            },
          ];
        case "priority":
          return [
            {
              title: "Code",
              dataIndex: "dictCode",
              key: "dictCode",
              width: 120,
              ellipsis: true,
            },
            {
              title: "Priority",
              dataIndex: "dictValue",
              key: "dictValue",
              ellipsis: true,
            },
            {
              title: "操作",
              dataIndex: "operation",
              slots: { customRender: "operation" },
              width: 80,
              fixed: "right",
            },
          ];
        case "industryLine":
          return [
            {
              title: "P&L",
              dataIndex: "pl",
              key: "pl",
              width: 200,
              ellipsis: true,
            },
            {
              title: "Sub_P&L",
              dataIndex: "subPl",
              key: "subPl",
              width: 200,
              ellipsis: true,
            },
            {
              title: "Industry Line",
              dataIndex: "industryLine",
              key: "industryLine",
              width: 200,
              ellipsis: true,
            },
            {
              title: "DQR Lead",
              dataIndex: "dqrLead",
              key: "dqrLead",
              width: 200,
              ellipsis: true,
            },
            {
              title: "P&L Operation",
              dataIndex: "plOperation",
              key: "plOperation",
              width: 200,
              ellipsis: true,
            },
            {
              title: "P&L Lead",
              dataIndex: "plLead",
              key: "plLead",
              width: 200,
              ellipsis: true,
            },
            {
              title: "Sub_P&L Lead",
              dataIndex: "subPlLead",
              key: "subPlLead",
              width: 200,
              ellipsis: true,
            },
          ];
        case "subServiceLine":
          return [
            {
              title: "Sub Service Line",
              dataIndex: "dictValue",
              key: "dictValue",
              width: 200,
              ellipsis: true,
            },
            {
              title: "Service Line",
              dataIndex: "dictRemark",
              key: "dictRemark",
              width: 200,
              ellipsis: true,
            },
          ];
        case "role":
          return [
            {
              title: "Role ID",
              dataIndex: "dictCode",
              key: "dictCode",
              width: 120,
              ellipsis: true,
            },
            {
              title: "Role",
              dataIndex: "dictValue",
              key: "dictValue",
              ellipsis: true,
            },
            {
              title: "操作",
              dataIndex: "operation",
              slots: { customRender: "operation" },
              width: 80,
              fixed: "right",
            },
          ];
        default:
          return [
            {
              title: "操作",
              dataIndex: "operation",
              slots: { customRender: "operation" },
              width: 80,
              fixed: "right",
            },
          ];
      }
    });

    const tableHeight = ref(0);
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    const delClick = (record) => {
      ctx.emit("del-click", record);
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      delClick,
      dictionaryListRef,
      columns,
      pagination,
      dictionaryList,
      searchClick,
      doSearch,
      tableHeight,
      editClick,
      searchIndustryLine,
      btnList
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  > tr > th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  > tr > td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>
